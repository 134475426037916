<template>
    <div id="pageTable">
        <v-container
            class="d-flex align-center justify-center"
            style="height: 80vh; width: 80vw"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa'"
        >
            <v-card class="" style="border-radius: 20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal" />
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn class="btnGuardar" @click="redireccionar()"> Ok </v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tablaAcciones"
                        :tableName="'Acciones Disciplinarias'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        :perPage="[10, 25, 50, 100]"
                        @setFilters="setFilters"
                        @loadModalData="loadModalData"
                        :showAdvancedFilters="true"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" v-if="permisos.create">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon> Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                            <div
                                class="icon-container d-flex align-center justify-center mr-3"
                                @click="abrirModalLayout('reporte')"
                            >
                                <v-icon class="tamIcono invertirColor">get_app</v-icon>
                            </div>
                            <!-- <v-menu offset-y content-class="menuClassAcciones ">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        class="btnAcciones pr-1 mr-3"
                                        v-bind="attrs"
                                        v-on="on"
                                        elevation="0"
                                    >
                                        Acciones
                                        <template v-for="(item, valor) in attrs">
                                            <v-icon
                                                :key="valor"
                                                v-if="valor == 'aria-expanded' && item == 'true'"
                                                class="pl-2"
                                                color="#FFFFFF"
                                                >expand_less</v-icon
                                            >
                                            <v-icon
                                                :key="valor"
                                                v-if="valor == 'aria-expanded' && item == 'false'"
                                                class="pl-2"
                                                color="#FFFFFF"
                                                >expand_more</v-icon
                                            >
                                        </template>
                                    </v-btn>
                                </template>
                                <div class="btnAccionesContenedor">
                                    <div
                                        @click="abrirModalLayout('reporte')"
                                        class="btnAccionesItem py-2"
                                    >
                                        <span>Descargar reporte</span>
                                    </div>
                                </div>
                            </v-menu> -->
                        </template>

                        <template slot="filters">
                            <v-col
                                v-if="rol == 'root'"
                                cols="12"
                                xs="12"
                                sm="12"
                                md="12"
                                class="py-0"
                            >
                                <v-autocomplete
                                    outlined
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cliente"
                                    persistent-hint
                                    v-model="cliente_value"
                                    no-data-text="Datos no disponibles"
                                />
                            </v-col>
                            <v-col
                                v-if="rol == 'root' || rol == 'admin'"
                                cols="12"
                                xs="12"
                                sm="12"
                                md="12"
                                class="py-0"
                            >
                                <v-autocomplete
                                    outlined
                                    :items="empresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                    no-data-text="Datos no disponibles"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Nombre Empleado"
                                    class="pa-0 ma-0"
                                    v-model="filterNombre"
                                ></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="6" md="6" class="py-0">
                                <date-picker
                                    outlined
                                    label="Fecha inicial"
                                    :valorDefecto="fecha_minima"
                                    v-model="fecha_minima"
                                    placeholder="dd/mm/aaaa"
                                    ref="Fecha_minima"
                                />
                            </v-col>
                            <v-col xs="12" sm="6" md="6" class="py-0">
                                <date-picker
                                    outlined
                                    label="Fecha final"
                                    :valorDefecto="fecha_maxima"
                                    v-model="fecha_maxima"
                                    placeholder="dd/mm/aaaa"
                                    ref="Fecha_maxima"
                                />
                            </v-col>
                        </template>

                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td>
                                    <div class="tblPrincipal">{{ item.nombre_completo }}</div>
                                </td>
                                <td>
                                    <div class="tblPrincipal">{{ item.puesto }}</div>
                                </td>
                                <template v-if="rol == 'root'">
                                    <td>
                                        <div class="tblPrincipal">{{ item.cliente }}</div>
                                    </td>
                                </template>
                                <template v-if="rol == 'admin'">
                                    <td>
                                        <div class="tblPrincipal">{{ item.empresa }}</div>
                                    </td>
                                </template>
                                <td>
                                    <div class="tblPrincipal">{{ item.tipo }}</div>
                                </td>
                                <td>
                                    <div class="tblPrincipal">{{ dateFormat(item.fecha) }}</div>
                                </td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-menu
                                            buttom
                                            left
                                            class="elevation-0 d-inline"
                                            content-class="mt-0 menuClassOpciones"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon
                                                    fab
                                                    dark
                                                    small
                                                    class="elevation-0"
                                                >
                                                    <template v-for="(item, valor) in attrs">
                                                        <v-icon
                                                            :key="valor"
                                                            v-if="
                                                                valor == 'aria-expanded' &&
                                                                item == 'false'
                                                            "
                                                            class="pl-2"
                                                            color="#828282"
                                                            >more_vert</v-icon
                                                        >
                                                        <v-icon
                                                            :key="valor"
                                                            v-if="
                                                                valor == 'aria-expanded' &&
                                                                item == 'true'
                                                            "
                                                            class="pl-2"
                                                            color="#2DB9D1"
                                                            >more_vert</v-icon
                                                        >
                                                    </template>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-list-item @click="abrirModal('update', item)">
                                                    <v-list-item-title>Editar</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="eliminar(item)" v-if="permisos.delete">
                                                    <v-list-item-title>Eliminar</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    v-if="item.url_archivo != null"
                                                    @click="descargarArchivo(item)"
                                                >
                                                    <v-list-item-title>Descargar</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <!--MODAL-->
            <template>
                <v-dialog v-model="dialog" persistent max-width="750px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <v-container grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <template v-if="rol == 'root'">
                                            <v-col xs="12" sm="6" md="6" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    label="Cliente"
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                    class="pa-0 ma-0"
                                                    :disabled="disable"
                                                ></v-autocomplete>
                                            </v-col>
                                        </template>
                                        <template v-if="rol == 'root' || rol == 'admin'">
                                            <v-col xs="12" sm="6" md="6" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    label="Empresa"
                                                    :items="empresas"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="empresa_value"
                                                    class="pa-0 ma-0"
                                                    :disabled="disable"
                                                ></v-autocomplete>
                                            </v-col>
                                        </template>
                                        <v-col xs="12" sm="6" md="6" class="py-0">
                                            <v-autocomplete
                                                outlined
                                                label="Sucursal"
                                                :items="sucursales"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="sucursal_value"
                                                class="pa-0 ma-0"
                                                :disabled="disable"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col xs="12" sm="6" md="6" class="py-0">
                                            <v-autocomplete
                                                outlined
                                                label="Departamento"
                                                :items="departamentos"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="departamento_value"
                                                class="pa-0 ma-0"
                                                :disabled="disable"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                name="Empleado"
                                                rules="required"
                                            >
                                                <v-text-field
                                                    v-if="disable"
                                                    outlined
                                                    v-model="nombre_empleado"
                                                    label="Empleado"
                                                    :error-messages="errors"
                                                    required
                                                    class="pa-0 ma-0"
                                                    disabled
                                                ></v-text-field>
                                                <v-autocomplete
                                                    v-else
                                                    outlined
                                                    label="Empleado"
                                                    :items="empleados"
                                                    item-text="nombre_completo"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="empleado_value"
                                                    :error-messages="errors"
                                                    required
                                                    class="pa-0 ma-0"
                                                ></v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col xs="12" sm="6" md="6" class="py-0">
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                name="Tipo"
                                                rules="required"
                                            >
                                                <v-autocomplete
                                                    outlined
                                                    label="Tipo"
                                                    :items="tipos_acciones"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="accion_disciplinaria.tipo_id"
                                                    :error-messages="errors"
                                                    required
                                                    class="pa-0 ma-0"
                                                ></v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col xs="12" sm="6" md="6" class="py-0">
                                            <date-picker
                                                outlined
                                                rules="required"
                                                label="Fecha"
                                                :valorDefecto="accion_disciplinaria.fecha"
                                                v-model="accion_disciplinaria.fecha"
                                                placeholder="dd/mm/aaaa"
                                                ref="Fecha"
                                            />
                                        </v-col>
                                        <v-col
                                            xs="12"
                                            :sm="rol == 'admin' ? '12' : '6'"
                                            :md="rol == 'admin' ? '12' : '6'"
                                            class="py-0"
                                        >
                                            <v-file-input
                                                outlined
                                                label="Seleccionar archivo"
                                                v-model="accion_disciplinaria.archivo"
                                                prepend-icon=""
                                                append-icon="attach_file"
                                                accept=".doc,.pdf,.jpg,.png"
                                                id="fileDocument"
                                                @click:append="open"
                                            ></v-file-input>
                                        </v-col>
                                        <v-col xs="12" sm="12" md="12" class="py-0">
                                            <v-textarea
                                                outlined
                                                label="Descripción"
                                                class="pa-0 ma-0"
                                                :rows="3"
                                                :no-resize="true"
                                                v-model="accion_disciplinaria.descripcion"
                                            ></v-textarea>
                                        </v-col>
                                        <v-col xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                name="Solucion"
                                                rules="required"
                                            >
                                                <v-textarea
                                                    outlined
                                                    label="Solución"
                                                    class="pa-0 ma-0"
                                                    :rows="3"
                                                    :no-resize="true"
                                                    v-model="accion_disciplinaria.solucion"
                                                    :error-messages="errors"
                                                    required
                                                ></v-textarea>
                                            </ValidationProvider>
                                            
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <v-btn
                                    class="btnCerrar"
                                    @click="cerrarModal()"
                                    :disabled="isSaving"
                                    :elevation="0"
                                >
                                    Cancelar
                                </v-btn>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()"
                                    :disabled="invalid"
                                    :loading="isSaving"
                                    :elevation="0"
                                    v-if="(permisos.update) || (permisos.create && permisos.update == false && accion == 'add')"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                            <div class="px-15 pb-10" v-if="permisos.update == false && accion == 'update'">
                                <v-alert
                                    dense
                                    outlined
                                    type="error"
                                    
                                >
                                    El usuario no tiene permiso para editar el registro.
                                </v-alert>
                            </div>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

            <!--MODAL REPORTE-->
            <template>
                <v-dialog v-model="dialogLayout" persistent max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            style="display: none"
                            class="botonModal"
                            id="btnModalLayout"
                        ></v-btn>
                    </template>
                    <v-card>
                        <ValidationObserver ref="formLayout" v-slot="{ invalid }">
                            <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                                <div class="headerModalStart">
                                    <h2 class="titleModal_700_18">{{ tituloLayout }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <v-container grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col
                                            v-if="rol == 'root'"
                                            cols="12"
                                            xs="12"
                                            sm="12"
                                            md="12"
                                            class="py-0"
                                        >
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                name="Cliente"
                                                rules="required"
                                            >
                                                <v-autocomplete
                                                    outlined
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Cliente"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                    :error-messages="errors"
                                                    no-data-text="Datos no disponibles"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col
                                            v-if="rol == 'root' || rol == 'admin'"
                                            cols="12"
                                            xs="12"
                                            sm="12"
                                            md="12"
                                            class="py-0"
                                        >
                                            <ValidationProvider
                                                v-slot="{ errors }"
                                                name="Empresa"
                                                rules="required"
                                            >
                                                <v-autocomplete
                                                    outlined
                                                    :items="empresas"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Empresa"
                                                    persistent-hint
                                                    v-model="empresa_value"
                                                    :error-messages="errors"
                                                    no-data-text="Datos no disponibles"
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <v-autocomplete
                                                outlined
                                                :items="sucursales"
                                                item-text="nombre"
                                                item-value="id"
                                                label="Sucursal"
                                                persistent-hint
                                                v-model="sucursal_value"
                                                no-data-text="Datos no disponibles"
                                            />
                                        </v-col>
                                        <v-col xs="12" sm="6" md="6" class="py-0">
                                            <date-picker
                                                outlined
                                                label="Fecha inicial"
                                                :valorDefecto="fecha_minima"
                                                v-model="fecha_minima"
                                                placeholder="dd/mm/aaaa"
                                                ref="Fecha_minima"
                                            />
                                        </v-col>
                                        <v-col xs="12" sm="6" md="6" class="py-0">
                                            <date-picker
                                                outlined
                                                label="Fecha final"
                                                :valorDefecto="fecha_maxima"
                                                v-model="fecha_maxima"
                                                placeholder="dd/mm/aaaa"
                                                ref="Fecha_maxima"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()"
                                    :disabled="isSaving"
                                >
                                    Cerrar
                                </button>
                                <v-btn
                                    :elevation="0"
                                    class="btnGuardar"
                                    v-if="accionLayout === 'reporte'"
                                    @click="descargarReporte()"
                                    :loading="isSaving"
                                    >Descargar</v-btn
                                >
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>
    import Datatable from "@/components/datatable/Datatable";
    import clientesApi from "@/api/clientes";
    import empresasApi from "@/api/empresas";
    import sucursalesApi from "@/api/sucursales";
    import departamentosApi from "../api/departamentos";
    import empleadosApi from "@/api/empleados";
    import VuetifyDatePicker from "@/components/VuetifyDatePicker.vue";
    import tiposAccionesApi from "@/api/tiposAccionesDisc";
    import accionesDisciplinariasApi from "@/api/accionesDisciplinarias";
    import Notify from "@/plugins/notify";

    export default {
        components: {
            "data-table": Datatable,
            "date-picker": VuetifyDatePicker,
        },
        data() {
            return {
                datosLogin: null,
                rol: null,
                url: "llamado_atencion/find",
                columns: [
                    {
                        label: "Empleado",
                        name: "empleado",
                        filterable: false,
                    },
                    {
                        label: "Puesto",
                        name: "puesto",
                        filterable: false,
                    },
                    {
                        label: "Tipo de acción disciplinaria",
                        name: "tipo",
                        filterable: false,
                    },
                    {
                        label: "Fecha",
                        name: "fecha",
                        filterable: false,
                    },
                    {
                        label: "Opciones",
                        name: "opciones",
                        align: "center",
                        filterable: false,
                    },
                ],
                filters: {
                    activo: true,
                    paginate: true,
                    cliente_id: null,
                    empresa_id: null,
                    empleado: "",
                    order_by: "tipo",
                    order: "asc",
                    min_fecha: null,
                    max_fecha: null,
                },
                imagen: "/static/modal/accion_disciplinaria.svg",
                cliente_value: null,
                empresa_value: null,
                sucursal_value: null,
                departamento_value: null,
                empleado_value: null,
                clientes: [],
                empresas: [],
                sucursales: [],
                departamentos: [],
                empleados: [],
                tipos_acciones: [],
                accion: "",
                dialog: false,
                tituloModal: "",
                filterNombre: "",
                isSaving: false,
                dialogLayout: false,
                tituloLayout: "",
                accionLayout: "",
                accion_disciplinaria: {
                    descripcion: "",
                    solucion: "",
                    tipo_id: null,
                    fecha: null,
                    archivo: null,
                },
                fecha_minima: null,
                fecha_maxima: null,
                disable: false,
                nombre_empleado: null,
                permisos :{
                    create: false,
                    update: false,
                    delete: false,
                }
            };
        },
        watch: {
            cliente_value: function (val) {
                if(val != null){
                    this.getEmpresas();
                }
                
            },
            empresa_value: function (val) {
                if(val != null){
                    this.getSucursales();
                    this.getAcciones();
                }
                
            },
            sucursal_value: function (val) {
                if(val != null){
                    this.getDepartamentos();
                }
            },
            departamento_value: function (val) {
                if(val != null){
                    if(this.disable != true){
                        this.getEmpleados();
                    }
                }
            },
        },
        methods: {
            listar() {
                if (this.rol == "root") {
                    this.getClientes();
                    this.columns = [
                        {
                            label: "Empleado",
                            name: "empleado",
                            filterable: false,
                        },
                        {
                            label: "Puesto",
                            name: "puesto",
                            filterable: false,
                        },
                        {
                            label: "Cliente",
                            name: "cliente",
                            filterable: false,
                        },
                        {
                            label: "Tipo de acción disciplinaria",
                            name: "tipo",
                            filterable: false,
                        },
                        {
                            label: "Fecha",
                            name: "fecha",
                            filterable: false,
                        },
                        {
                            label: "Opciones",
                            name: "opciones",
                            align: "center",
                            filterable: false,
                        },
                    ];
                } else if (this.rol == "admin") {
                    this.filters.cliente_id = this.datosLogin.cliente_id;
                    this.cliente_value = this.datosLogin.cliente_id;

                    this.columns = [
                        {
                            label: "Empleado",
                            name: "empleado",
                            filterable: false,
                        },
                        {
                            label: "Puesto",
                            name: "puesto",
                            filterable: false,
                        },
                        {
                            label: "Empresa",
                            name: "empresa",
                            filterable: false,
                        },
                        {
                            label: "Tipo de acción disciplinaria",
                            name: "tipo",
                            filterable: false,
                        },
                        {
                            label: "Fecha",
                            name: "fecha",
                            filterable: false,
                        },
                        {
                            label: "Opciones",
                            name: "opciones",
                            align: "center",
                            filterable: false,
                        },
                    ];
                } else if (this.rol == "admin-empresa") {
                    this.filters.cliente_id = this.datosLogin.cliente_id;
                    this.filters.empresa_id = this.datosLogin.empresa_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                }
            },
            async getClientes() {
                let parametros = {
                    activo: true,
                    paginate: false,
                    order: "asc",
                    order_by: "nombre",
                };

                await clientesApi
                    .getClientes(parametros)
                    .then((response) => {
                        this.clientes = response.data;
                    })
                    .catch((err) => {
                        console.error(err);
                        Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
                    });
            },
            getEmpresas() {
                let parametros = {
                    activo: true,
                    paginate: false,
                    order: "asc",
                    order_by: "nombre",
                    cliente_id: this.cliente_value,
                };
                empresasApi
                    .getEmpresas(parametros)
                    .then((response) => {
                        this.empresas = response.data;
                    })
                    .catch((err) => {
                        console.error(err);
                        Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
                    });
            },
            getSucursales() {
                let parametros = {
                    activo: true,
                    paginate: false,
                    order: "asc",
                    order_by: "nombre",
                    cliente_id: this.cliente_value,
                    empresa_id: this.empresa_value,
                };
                sucursalesApi
                    .getSucursales(parametros)
                    .then((response) => {
                        this.sucursales = response.data;
                    })
                    .catch((err) => {
                        console.error(err);
                        Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
                    });
            },
            getDepartamentos() {
                let parametros = {
                    activo: true,
                    paginate: false,
                    order: "asc",
                    order_by: "nombre",
                    cliente_id: this.cliente_value,
                    empresa_id: this.empresa_value,
                    sucursal_id: this.sucursal_value,
                };
                departamentosApi
                    .getDepartamentos(parametros)
                    .then((response) => {
                        this.departamentos = response.data;
                    })
                    .catch((err) => {
                        console.error(err);
                        Notify.ErrorToast("Ocurrió un error al cargar los departamentos.");
                    });
            },
            getEmpleados() {
                let parametros = {
                    activo: true,
                    paginate: false,
                    order: "asc",
                    order_by: "nombre",
                    cliente_id: this.cliente_value,
                    empresa_id: this.empresa_value,
                    sucursal_id: this.sucursal_value,
                    departamento_id: this.departamento_value,
                    rol_name: this.datosLogin.rol.name,
                    usuario_id: this.datosLogin.id,
                };
                empleadosApi
                    .getEmpleados(parametros)
                    .then((response) => {
                        this.empleados = response.data;
                    })
                    .catch((err) => {
                        console.error(err);
                        Notify.ErrorToast("Ocurrió un error al cargar los empleados.");
                    });
            },
            getAcciones() {
                let parametros = {
                    activo: true,
                    paginate: false,
                    cliente_id: this.cliente_value,
                    empresa_id: this.empresa_value,
                };
                tiposAccionesApi
                    .getTiposAccionesDisciplinarias(parametros)
                    .then((response) => {
                        this.tipos_acciones = response.data;
                    })
                    .catch((err) => {
                        console.error(err);
                        Notify.ErrorToast(
                            "Ocurrió un error al cargar los tipos de acciones disciplinarias."
                        );
                    });
            },
            abrirModal(accion, item = null) {
                this.accion = accion;
                this.dialog = true;

                if (this.rol == "root") {
                    this.getClientes();
                }
                if (accion == "add") {
                    this.tituloModal = "Agregar Acción Disciplinaria";
                    if (this.rol == "root") {

                    }
                    else if (this.rol == "admin") {
                        this.cliente_value = this.datosLogin.cliente_id;
                    }  
                    else{
                        this.cliente_value = this.datosLogin.cliente_id;
                        this.empresa_value = this.datosLogin.empresa_id;
                    }
                } else if (accion == "update") {
                    this.tituloModal = "Actualizar Acción Disciplinaria";
                    this.cliente_value = item.cliente_id;
                    this.empresa_value = item.empresa_id;
                    this.sucursal_value = item.sucursal_id;
                    this.departamento_value = item.departamento_id;
                    this.empleado_value = item.empleado_id;
                    this.accion_disciplinaria.descripcion = item.descripcion;
                    this.accion_disciplinaria.solucion = item.solucion;
                    this.accion_disciplinaria.tipo_id = item.tipo_id;
                    this.accion_disciplinaria.fecha = item.fecha;
                    this.accion_disciplinaria.id = item.id;

                    this.nombre_empleado = item.nombre_completo
                    
                    if(item.empleado_baja == true){
                        this.disable = true
                    }
                    else{
                        this.disable = false
                    }
                }
            },
            cerrarModal() {
                this.dialog = false;
                this.dialogLayout = false;
                this.resetValues();
                if(this.$refs.form){
                    this.$refs.form.reset();
                }
                if(this.$refs.formLayout){
                    this.$refs.formLayout.reset();
                }
            },
            loadModalData() {
                if (this.rol == "root") {
                    this.getClientes();
                } 
                else if (this.rol == "admin") {
                    this.cliente_value = this.datosLogin.cliente_id;
                } 
                else{
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                }
            },
            async setFilters() {
                let cliente = this.cliente_value;
                let empresa = this.empresa_value;
                let empleado = this.filterNombre;

                this.filters.cliente_id = cliente;
                this.filters.empresa_id = empresa;
                this.filters.empleado = empleado;
                this.filters.min_fecha = this.fecha_minima;
                this.filters.max_fecha = this.fecha_maxima;

                this.$refs.tablaAcciones.getData();
                await this.resetValues();
            },
            guardar() {
                this.$refs.form.validate().then((success) => {
                    this.isSaving = true;
                    if (!success) {
                        this.isSaving = false;
                        return;
                    }

                    let formData = new FormData();
                    if(this.accion_disciplinaria.descripcion != null || this.accion_disciplinaria.descripcion != ""){
                        formData.append("descripcion", this.accion_disciplinaria.descripcion);
                    }
                    formData.append("solucion", this.accion_disciplinaria.solucion);
                    formData.append("tipo_id", this.accion_disciplinaria.tipo_id);
                    formData.append("fecha", this.accion_disciplinaria.fecha);
                    formData.append("empleado_id", this.empleado_value);
                    if (this.accion_disciplinaria.archivo != null) {
                        formData.append("archivo", this.accion_disciplinaria.archivo);
                    }

                    if (this.accion === "add") {
                        accionesDisciplinariasApi
                            .addAccionDisciplinaria(formData)
                            .then((resp) => {
                                Notify.Success(
                                    "Operación exitosa",
                                    "La Acción Disciplinaria se agregó satisfactoriamente"
                                );
                                this.$refs.tablaAcciones.getData();
                                this.isSaving = false;
                                this.cerrarModal();
                                this.$nextTick(() => {
                                    this.$refs.form.reset();
                                });
                            })
                            .catch((err) => {
                                this.isSaving = false;
                                if (typeof err.response.data.error === "string") {
                                    Notify.ErrorToast(err.response.data.error);
                                } else {
                                    let error = Object.values(err.response.data.error);
                                    let leyenda = ``;

                                    for (var i = 0; i < error.length; i++) {
                                        leyenda += `* ` + error[i] + `\n`;
                                    }
                                    Notify.ErrorToast(leyenda);
                                }
                            });
                    } else if (this.accion === "update") {
                        if (this.accion_disciplinaria.id) {
                            formData.append("id", this.accion_disciplinaria.id);
                        }
                        accionesDisciplinariasApi
                            .updateAccionDisciplinaria(formData)
                            .then((resp) => {
                                Notify.Success(
                                    "Operación exitosa",
                                    "La Acción Disciplinaria se actualizó satisfactoriamente"
                                );
                                this.$refs.tablaAcciones.getData();
                                this.isSaving = false;
                                this.cerrarModal();
                                this.$nextTick(() => {
                                    this.$refs.form.reset();
                                });
                            })
                            .catch((err) => {
                                this.isSaving = false;
                                if (typeof err.response.data.error === "string") {
                                    Notify.ErrorToast(err.response.data.error);
                                } else {
                                    let error = Object.values(err.response.data.error);
                                    let leyenda = ``;

                                    for (var i = 0; i < error.length; i++) {
                                        leyenda += `* ` + error[i] + `\n`;
                                    }
                                    Notify.ErrorToast(leyenda);
                                }
                            });
                    }
                });
            },
            eliminar(item) {
                Notify.Alert(
                    "ADVERTENCIA",
                    "¿Estás seguro que deseas eliminar la acción disciplinaria " +
                        "?",
                    "Eliminar",
                    () => {
                        accionesDisciplinariasApi
                            .deleteAccionDisciplinaria(item.id)
                            .then((resp) => {
                                Notify.Success(
                                    "Operación Exitosa",
                                    "La acción disciplinaria ha sido eliminada correctamente"
                                );
                                this.$refs.tablaAcciones.getData();
                            })
                            .catch((err) => {
                                if (typeof err.response.data.error === "string") {
                                    Notify.ErrorToast(err.response.data.error);
                                } else {
                                    let error = Object.values(err.response.data.error);
                                    let leyenda = ``;

                                    for (var i = 0; i < error.length; i++) {
                                        leyenda += `* ` + error[i] + `\n`;
                                    }

                                    Notify.ErrorToast(leyenda);
                                }
                            });
                    }
                );
            },
            abrirModalLayout(accionLayout = "") {
                this.accionLayout = accionLayout;
                if (accionLayout == "reporte") {
                    this.tituloLayout = "Descargar reporte";
                }

                if (this.rol == "root") {
                    this.getClientes();
                } 
                else if (this.rol == "admin") {
                    this.cliente_value = this.datosLogin.cliente_id;
                }
                else {
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                }

                this.dialogLayout = true;
            },
            async descargarReporte() {
                let self = this;
                this.$refs.formLayout.validate().then(async (success) => {
                    this.isSaving = true;
                    if (!success) {
                        this.isSaving = false;
                        return;
                    }

                    this.isSaving = true;
                    const FileDownload = require("js-file-download");
                    let today = new Date();
                    let nombre = "";

                    nombre =
                        "Reporte_acciones_disciplinarias_" +
                        today.getDate() +
                        "_" +
                        (today.getMonth() + 1) +
                        "_" +
                        today.getFullYear() +
                        ".xlsx";

                    let cliente = this.cliente_value;
                    let empresa = this.empresa_value;
                    let sucursal = this.sucursal_value;

                    if (this.rol == "admin-empresa") {
                        cliente = this.datosLogin.cliente_id;
                        empresa = this.datosLogin.empresa_id;
                    }

                    let parametros = {
                        rol_name: this.rol,
                        usuario_id: this.datosLogin.id,
                        tipo: "xlsx",
                        cliente_id: cliente,
                        empresa_id: empresa,
                        sucursal_id: sucursal,
                        min_fecha: this.fecha_minima,
                        max_fecha: this.fecha_maxima,
                        activo: true,
                    };

                    try {
                        await accionesDisciplinariasApi
                            .descargarReporte(parametros)
                            .then((resp) => {
                                this.isSaving = false;
                                FileDownload(resp.data, nombre);
                                this.cerrarModal();
                            })
                            .catch(async (error) => {
                                self.isSaving = false;
                                let errorString = error.response.data;
                                if (
                                    error.request.responseType === "blob" &&
                                    error.response.data instanceof Blob &&
                                    error.response.data.type &&
                                    error.response.data.type.toLowerCase().indexOf("json") != -1
                                ) {
                                    errorString = JSON.parse(await error.response.data.text());

                                    if (typeof errorString.error === "string") {
                                        Notify.ErrorToast(errorString.error);
                                    } else {
                                        let error = Object.values(errorString.error);
                                        let leyenda = ``;

                                        for (var i = 0; i < error.length; i++) {
                                            leyenda += `* ` + error[i] + `\n`;
                                        }
                                        Notify.ErrorToast(leyenda);
                                    }
                                }
                            });
                    } catch (e) {
                        self.isSaving = false;
                        Notify.ErrorToast("Se produjo un error al realizar la petición.");
                    }
                });
            },
            async descargarArchivo(item) {
                let empleado = item.nombre_completo;
                let archivo = item.url_archivo;
                const FileDownload = require("js-file-download");
                const axios = require("axios");
                let url = "download";
                let parametros = { url: archivo };

                var nombre_archivo = "";
                let nombreArchivo = "";
                let archivo_extension = "";

                if (archivo) {
                    nombreArchivo = archivo.split("/");
                    archivo_extension = nombreArchivo[nombreArchivo.length - 1].split(".");
                    nombre_archivo =
                        empleado + "." + archivo_extension[archivo_extension.length - 1];
                }

                try {
                    axios({
                        method: "post",
                        url: url,
                        responseType: "blob",
                        data: parametros,
                    }).then((response) => {
                        FileDownload(response.data, nombre_archivo);
                    });
                } catch (e) {
                    Notify.ErrorToast("Se produjo un error al realizar la petición.");
                    console.log(e);
                }
            },
            async resetValues() {
                this.cliente_value = null;
                this.empresa_value = null;
                this.sucursal_value = null;
                this.departamento_value = null;
                this.empleado_value = null;
                this.tipos_acciones = [];
                this.clientes = [];
                this.empresas = [];
                this.sucursales = [];
                this.departamentos = [];
                this.empleados = [];
                this.accion_disciplinaria = {
                    descripcion: "",
                    solucion: "",
                    tipo_id: null,
                    fecha: null,
                    archivo: null,
                };
                this.filterNombre = "";
                this.fecha_minima = null;
                this.fecha_maxima = null;
                this.disable = false;
                this.nombre_empleado = null;
            },
            open() {
                document.getElementById("fileDocument").click();
            },
            redireccionar() {
                this.$router.push("/dashboard");
            },
            dateFormat(date) {
                let dateFirst = date.split("-");
                return dateFirst[2] + "/" + dateFirst[1] + "/" + dateFirst[0];
            },
            permisosVista(){
                let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))
                this.permisos.create = permisosStorage.includes("llamado_atencion.store")
                this.permisos.update = permisosStorage.includes("llamado_atencion.update")
                this.permisos.delete = permisosStorage.includes("llamado_atencion.delete")
            },
        },
        created() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;
            this.listar();
            this.permisosVista();
        },
    };
</script>

<style scoped>
    .menuClassAcciones {
        /* box-shadow: none; */
        border-radius: 10px !important;
        margin-top: 15px;
    }
    .btnAcciones {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background: #58c5d8 !important;
        color: #ffffff !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }
    .btnAcciones:hover {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background: #58c5d8 !important;
        color: #ffffff !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        box-shadow: 0px 5px 8px rgba(26, 30, 66, 0.2);
        -webkit-transform: translatey(-1px);
        transform: translatey(-1px);
    }
    .btnAccionesContenedor {
        width: 300px;
        height: auto;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: #fff;
    }
    .btnAddContenedor {
        width: 250px;
        height: 140px;
        padding-top: 30px;
        background-color: #fff;
    }
    div.btnAccionesTitulo {
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesTitulo span {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        color: #1e2245;
    }
    div.btnAccionesItem {
        width: 100%;
        cursor: pointer;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesItem span {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    div.btnAccionesItem:hover {
        background: #f6f6f8;
    }

    .menuClassAccionesVer {
        border-radius: 10px !important;
    }
    .menu-width {
        max-width: 500px;
        min-width: 450px;
        border-radius: 20px !important;
    }
    .menuClassOpciones {
        border-radius: 10px !important;
    }

    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1.5px !important;
        -webkit-text-stroke-color: rgba(0, 0, 0, 0.54);
    }
    .icon-container {
        width: 42px;
        height: 42px;
        background: #ffffff;
        border-radius: 100%;

        text-align: center;
        /* justify-content: center;
        align-content: center; */
        /* padding: 11px; */
    }

    .icon-container:hover {
        box-shadow: 0px 5px 8px rgba(26, 30, 66, 0.2);
        -webkit-transform: translatey(-1px);
        transform: translatey(-1px);
        cursor: pointer;
    }
</style>
